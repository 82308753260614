<template>
  <v-dialog
    scrollable
    max-width="800px"
    transition="dialog-top-transition"
    v-model="dialog"
  >
    <template #activator="{ on }">
      <v-btn color="primary" text icon v-on="on" v-if="!!obSettings.id">
        <icon-edit size="1.5em" />
      </v-btn>
      <v-btn color="primary" depressed v-on="on" v-else>
        {{ $t("add") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <div class="text-h5" v-text="paymentMethod.name" />
      </v-card-title>

      <v-form class="fill-height" @submit.prevent v-if="dialog">
        <gw-form-observer
          @save="save"
          @cancel="close"
          :loading="loading"
          hide-top-actions
        >
          <v-row>
            <template v-for="sKey in arKeys">
              <v-col cols="12" lg="6" :key="`field-${sKey}`">
                <bank-select
                  :value="$_.get(obSettings, sKey)"
                  @input="$set(obSettings, sKey, $event)"
                  v-if="sKey == 'bank_name'"
                />

                <v-radio-group
                  row
                  :value="$_.get(obSettings, sKey)"
                  @change="$set(obSettings, sKey, $event)"
                  v-else-if="sKey == 'account_type'"
                >
                  <v-radio value="CC" label="Cuenta Corriente" />
                  <v-radio value="CA" label="Caja de Ahorros" />
                </v-radio-group>

                <form-field-date
                  v-else-if="['due_at', 'issue_at'].includes(sKey)"
                  :label="sKey"
                  :value="$_.get(obSettings, sKey)"
                  @input="$set(obSettings, sKey, $event)"
                  @save="save"
                />

                <form-field-card
                  :label="sKey"
                  :value="$_.get(obSettings, sKey)"
                  @input="$set(obSettings, sKey, $event)"
                  @save="save"
                  v-else-if="sKey == 'number'"
                />

                <form-field-text
                  :label="sKey"
                  :value="$_.get(obSettings, sKey)"
                  @input="$set(obSettings, sKey, $event)"
                  @save="save"
                  v-else
                />
              </v-col>
            </template>
          </v-row>
        </gw-form-observer>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import type { Company, PaymentMethod } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import BankSelect from "@/modules/banks/components/BankSelect.vue";
import FormFieldCard from "@/components/form/fields/CreditCard.vue";
import { EventBus } from "@/services/event-bus";
import { InvoicePaymentMethodConfigItems } from "@/config/invoicePaymentMethods";

@Component({
  components: { BankSelect, FormFieldCard },
})
export default class CompanyPaymentMethodsDialog extends Vue {
  @VModel({ type: Object, default: () => ({}) }) obSettings!: Record<
    string,
    any
  >;
  @Prop(Object) readonly company!: Company;
  @Prop(Object) readonly paymentMethod!: PaymentMethod;

  // obSettings: Record<string, any> = {};
  dialog = false;

  @Watch("dialog")
  onClose(bDialog: boolean) {
    if (!bDialog) {
      this.close();
    }
  }

  close() {
    this.$emit("input", {});
    this.dialog = false;
  }

  async save() {
    if (
      this.paymentMethod.code == "TARJETA" &&
      this.$_.has(this.obSettings, "number")
    ) {
      this.$_.set(
        this.obSettings,
        "number",
        this.obSettings.number.replace(/\s+/g, "")
      );
    }

    const obResponse = await this.company.addUpdatePaymentMethods({
      company_id: this.company.id,
      paymentmethod_id: this.paymentMethod.id,
      config: this.obSettings,
    });

    if (obResponse) {
      EventBus.emit("company.update.payment_methods");
      this.close();
    }
  }

  get arKeys(): string[] {
    if (!this.paymentMethod || !this.paymentMethod.code) {
      return [];
    }

    return this.$_.get(
      InvoicePaymentMethodConfigItems,
      this.paymentMethod.code
    );
    // switch (this.paymentMethod.code) {
    //   case "CHEQUE":
    //     return ["bank", "holder"];

    //   case "CUENTA_BANCARIA":
    //     return ["bank", "account_number", "account_type"];

    //   case "TARJETA":
    //     return ["number", "holder"];

    //   default:
    //     break;
    // }

    // return [];
  }

  get loading() {
    return this.company ? this.company.loading : false;
  }
}
</script>
